<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ role.id ? 'Edit' : 'New' }} Role<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'admin.roles.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <form
            id="edit-form"
            action=""
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Content</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >Name</label>
                          <input
                            v-model="role.name"
                            :disabled="roleId ? !role.can_delete : false"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <!-- <div class="col-sm-2">
                        <div class="mb-1 form-group">
                          <label
                            for=""
                            class="form-label"
                          >Check all permissions</label>
                          <input
                            type="checkbox"
                            class="mx-1 form-checkbox-control"
                            @click="checkAllPermissions($event)"
                          >
                        </div>
                      </div> -->
                      <div class="col-sm-5">
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="mb-1 form-group">
                              <label
                                for=""
                                class="form-label"
                              >Check all permissions</label>
                              <input
                                type="checkbox"
                                class="mx-1 form-checkbox-control"
                                @click="checkAllPermissions($event)"
                              >
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="mb-1 form-group">
                              <label
                                for=""
                                class="form-label"
                              >Call evaluator</label>
                              <input
                                v-model="role.evaluator"
                                type="checkbox"
                                class="mx-1 form-checkbox-control"
                              >
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-1 form-group">
                              <label
                                for=""
                                class="form-label"
                              >Researcher</label>
                              <input
                                v-model="role.researcher"
                                type="checkbox"
                                class="mx-1 form-checkbox-control"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!loading"
                class="row"
              >
                <ManagePermission
                  v-for="(permission, index) in permissions"
                  :key="index"
                  :permission-name="index"
                  :item="permission"
                  :current-permissions="role.permissions"
                  :current-id="role.id"
                  :assign-to="'role'"
                  :check-all="checkAll"
                  @saveAdditionalPermissions="saveAdditionalPermissions($event)"
                />
              </div>
            </div>
          </form>

        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
// import vSelect from 'vue-select'
// import ManageRoles from './components/ManageRoles.vue'
import ManagePermission from '../../partials/components/ManagePermission.vue'

export default {
  components: {
    // vSelect,
    // ManageRoles,
    ManagePermission,
  },
  data() {
    return {
      roleId: this.$route.params.id,
      sending: false,
      errors: '',
      selectedPermissions: [],
      loading: true,
      checkAll: false,
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'permissions/permissions',
      role: 'roles/role',
    }),
  },
  async mounted() {
    if (this.roleId) {
      await this.$store.dispatch('roles/fetchRole', this.roleId)
    } else {
      await this.$store.dispatch('roles/cleanRole')
    }
    this.loading = false
    await this.$store.dispatch('permissions/fetch')
  },
  methods: {
    async save() {
      this.sending = true
      // this.role.permissions = this.selectedPermissions
      try {
        if (this.roleId) {
          await this.$store.dispatch('roles/update', { id: this.roleId, data: this.role })
        } else {
          await this.$store.dispatch('roles/create', this.role)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.role.id) {
        Vue.swal('The role has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.roles.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    saveAdditionalPermissions(permissions) {
      if (!this.role.id) {
        if (!Array.isArray(permissions)) {
          if (this.role.permissions.includes(permissions)) {
            this.role.permissions.splice(this.role.permissions.indexOf(permissions), 1)
          } else {
            this.role.permissions.push(permissions)
          }
        }
      } else {
        this.role.permissions = permissions
      }
    },
    checkAllPermissions(event) {
      event.stopPropagation()
      this.checkAll = event.target.checked
    },
  },

}
</script>

<template>
  <div
    class="col-4 kb-search-content"
  >
    <!-- account setting card -->
    <div
      class="card"
      :class="{'border-success': counterCheckeds + counterInheritCheckeds > 0, 'border-danger': counterCheckeds + counterInheritCheckeds < 1}"
    >
      <div class="card-body shadow">
        <!-- account setting header -->
        <div
          v-b-toggle="`collapsePermissions-${permissionName}`"
        >
          <div class="d-flex">
            <h6 class="kb-title w-100">
              <span
                class="me-50 text-capitalize"
              >{{ permissionName }}</span>
              <span>{{ counterCheckeds + counterInheritCheckeds }} / {{ item.length }}</span>
              <input
                type="checkbox"
                :checked="counterCheckeds === item.length || counterInheritCheckeds === item.length || (counterCheckeds + counterInheritCheckeds) === item.length"
                :disabled="counterInheritCheckeds === item.length"
                class="mx-1 form-checkbox-control checkcard"
                @click="checkAllCard($event)"
              >
            </h6>
            <span
              class="text-end"
            >
              <i
                data-feather="chevron-down"
              />
            </span>
          </div>
          <!-- <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li>
                <a data-action="collapse"><i data-feather="chevron-down" /></a>
              </li>
            </ul>
          </div> -->
        </div>
        <b-collapse
          :id="`collapsePermissions-${permissionName}`"
          class="mx-1"
        >
          <ul class="list-group list-group-circle mt-2">
            <li
              v-for="(perm, index2) in item"
              :key="index2"
              class="list-group-item"
            >
              <label
                class="form-label"
              >{{ perm.name }}</label>
              <input
                v-if="assignTo == 'role'"
                type="checkbox"
                :checked="currentPermissions.includes(perm.id)"
                class="mx-1 form-checkbox-control"
                @click="checkPermissionsId(perm.id)"
              >
              <input
                v-if="assignTo == 'user'"
                ref="perm_items"
                type="checkbox"
                :checked="directPermissions.includes(perm.name) || inheritPermissions.includes(perm.name)"
                :disabled="inheritPermissions.includes(perm.name)"
                class="mx-1 form-checkbox-control"
                @click="checkPermissionsName(perm.name)"
              >
            </li>
          </ul>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    item: { type: Array, required: true, default: () => [] },
    permissionName: { type: String, required: true, default: () => '' },
    currentPermissions: { type: Array, required: false, default: () => [] },
    directPermissions: { type: Array, required: false, default: () => [] },
    inheritPermissions: { type: Array, required: false, default: () => [] },
    currentId: { type: Number, default: undefined },
    assignTo: { type: String, required: true, default: () => '' },
    checkAll: { type: Boolean, required: true, default: () => false },
  },
  data() {
    return {
      selectedPermissions: [],
      counterCheckeds: 0,
      counterInheritCheckeds: 0,
    }
  },
  watch: {
    async inheritPermissions() {
      await this.validateCommonPermissions()
      this.checkChekeds()
    },
    directPermissions() {
      this.checkChekeds()
    },
    currentPermissions() {
      this.checkChekeds()
    },
    checkAll() {
      this.checkAllPermissions()
    },
  },
  mounted() {
    this.checkChekeds()
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    checkPermissionsId(value) {
      if (this.currentId) {
        if (this.currentPermissions.includes(value)) {
          this.currentPermissions.splice(this.currentPermissions.indexOf(value), 1)
          this.counterCheckeds -= 1
        } else {
          this.currentPermissions.push(value)
          this.counterCheckeds += 1
        }
        this.$emit('saveAdditionalPermissions', this.currentPermissions)
      } else {
        this.$emit('saveAdditionalPermissions', value)
      }
    },
    checkPermissionsName(value) {
      if (this.currentId) {
        if (this.directPermissions.includes(value)) {
          this.directPermissions.splice(this.directPermissions.indexOf(value), 1)
          this.counterCheckeds -= 1
        } else {
          this.directPermissions.push(value)
          this.counterCheckeds += 1
        }
        this.$emit('saveAdditionalPermissions', this.directPermissions)
      } else {
        this.$emit('saveAdditionalPermissions', value)
      }
    },
    checkAllCard(event) {
      event.stopPropagation()
      if (this.assignTo === 'user') {
        this.item.forEach(it => {
          if (this.directPermissions.findIndex(x => x === it.name) < 0) {
            if (event.target.checked) {
              if (!this.inheritPermissions.includes(it.name)) {
                this.directPermissions.push(it.name)
                this.counterCheckeds += 1
              }
            }
          } else if (!event.target.checked) {
            this.directPermissions.splice(this.directPermissions.indexOf(it.name), 1)
            this.counterCheckeds -= 1
          }
        })
        this.$emit('saveAdditionalPermissions', this.directPermissions)
      } else if (this.assignTo === 'role') {
        this.item.forEach(it => {
          if (this.currentPermissions.findIndex(x => x === it.id) < 0) {
            if (event.target.checked) {
              this.currentPermissions.push(it.id)
              this.counterCheckeds += 1
            }
          } else if (!event.target.checked) {
            this.currentPermissions.splice(this.currentPermissions.indexOf(it.id), 1)
            this.counterCheckeds -= 1
          }
        })
        this.$emit('saveAdditionalPermissions', this.currentPermissions)
      }
    },
    checkChekeds() {
      if (this.assignTo === 'user') {
        this.counterCheckeds = 0
        this.counterInheritCheckeds = 0
        this.item.forEach(it => {
          if (this.inheritPermissions.findIndex(x => x === it.name) >= 0) {
            this.counterInheritCheckeds += 1
          }
          if (this.directPermissions.findIndex(x => x === it.name) >= 0) {
            this.counterCheckeds += 1
          }
        })
      } else if (this.assignTo === 'role') {
        this.counterCheckeds = 0
        this.item.forEach(it => {
          if (this.currentPermissions.findIndex(x => x === it.id) >= 0) {
            this.counterCheckeds += 1
          }
        })
      }
    },
    validateCommonPermissions() {
      this.directPermissions.forEach(permission => {
        if (this.inheritPermissions.includes(permission)) {
          this.directPermissions.splice(this.directPermissions.indexOf(permission), 1)
        }
      })
    },
    checkAllPermissions() {
      document.getElementsByClassName('checkcard').forEach(box => {
        if (this.checkAll) {
          if (!box.checked) {
            box.click()
          }
        } else if (!this.checkAll) {
          if (box.checked) {
            box.click()
          }
        }
      })
    },
  },
}
</script>

<style>

</style>
